//import React, { Component } from 'react';
import React from 'react';


//import { compose } from 'recompose';

import { withFirebase } from '../Firebase';

import ItemList from './item';

//import * as INFO from '../../constants/info';
/*
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../Session';
*/

class BookmarkBase extends React.Component {
  constructor(props) {
    super(props);
    const initalState = Object.keys(props.array).map(i => props.array[i]);

    this.state = {
        array: initalState
    }
  }


  componentDidMount() {

  };

  render() {
    var test = this.state.array.length > 1;
    return (
        <>
            { test ? 
            <ItemList array={this.state.array}/> : <p className="error">You don't have any Saved Manuals</p>  }
        </>
    );
  }
}

export default withFirebase(BookmarkBase);
