import React from 'react';
import { Link } from "gatsby"

import Layout from '../App/components/layout';
import Bookmark from '../components/Bookmark';
import OnSendEmailVerificationButton from '../components/Session/onSendEmailVerification';


import { AuthUserContext } from '../components/Session';
import SEO from "../App/components/seo"

const Bookmarks = () => {

    return(

        <Layout>
          <SEO title="" />
            <AuthUserContext.Consumer>
              {authUser =>
                authUser ? (
                  authUser.emailVerified ? (
                  <div className="wrapper">
                    <h1>Saved Manuals</h1>
                    {Object.keys(authUser.favorites).map(i => Object.keys(authUser.favorites)[i])}

                     <Bookmark title={Object.keys(authUser.favorites).title} array={authUser.favorites}/>
                  </div>
              ):
              <OnSendEmailVerificationButton/>
               ) : (
                <div className="wrapper"> You need to <Link to="/signin">log in</Link> to see restricted
                 content</div>
              )
            }
          </AuthUserContext.Consumer>
        </Layout>
    )
}

export default Bookmarks
